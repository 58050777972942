import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import { Container, Row, Col } from "./ui/grid";
import ShapeDown from "./ui/shape-down";
import Customers from "./customers";
import Button from "./ui/button";
import TextGradient from "./ui/text-gradient";
import Logo from "../svg/logo.svg";
import ContactUsForm from "./contact-us-form";

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null;

      if (type !== "ContentfulTextGradient") {
        return children;
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>;
    },
    [BLOCKS.HEADING_1]: (_node, children) => {
      return (
        <h1 className="text-white pr-12 lg:pr-20 xl:text-6xl">{children}</h1>
      );
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <p className="text-white pr-12 lg:pr-20 xl:text-xl">{children}</p>;
    },
  },
};

const HeroLanding = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageLanding {
          usersLogosTitle
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          buttonPrimary {
            title
            url
          }
        }
      }
    `
  );

  const content = data?.heroContent || "";
  const usersLogosTitle = data?.usersLogosTitle || "";
  const btnPrimary = data?.buttonPrimary || "";

  return (
    <ShapeDown className="pt-12 lg:pt-12">
      <section className="mb-12 overflow-hidden lg:mb-24">
        <Container>
          <Row className="mb-24">
            <Col size="w-full">
              <Logo className="w-auto h-7" />
            </Col>
          </Row>
          <Row>
            <Col size="w-full lg:w-1/2 py-4">
              {content && renderRichText(content, options)}

              <div className="mt-8 space-y-4 md:space-y-0 md:space-x-4">
                <Button
                  label={btnPrimary?.title}
                  to={btnPrimary?.url}
                  className="block md:inline-block"
                />
              </div>
            </Col>

            <Col size="w-full lg:w-1/2" className="order-last p-4">
              <div className="bg-white rounded p-8">
                <ContactUsForm 
                  formName='landing-form'
                  buttonClassName='btn-blue'
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <p className="mb-10 text-lg text-center text-gray-light font-normal opacity-75">
        {usersLogosTitle}
      </p>

      <Customers all/>
    </ShapeDown>
  );
};

export default HeroLanding;
