import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { gsap } from "../plugins/gsap";
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Row, Col } from "../components/ui/grid";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroLanding from "../components/hero-landing";

import Icon from "../svg/icon.svg";
import { FooterReadyFor } from "../components/ui/footer-ready-for";
import { Recognition } from "../components/ui/recognition";

gsap.registerPlugin(ScrollTrigger);

const options = {
  renderNode: {
    [BLOCKS.HEADING_3]: (_node, children) => {
      return (
        <div
          className="flex xl:text-3xl mb-4 items-center"
          style={{ gap: "1em" }}
        >
          <Icon style={{ height: "1em", width: "1em" }} />
          <h3 style={{ margin: 0, fontSize: "1em" }}>{children}</h3>
        </div>
      );
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return (
        <p className="xl:text-3xl mb-8" style={{ marginLeft: "2em" }}>
          {children}
        </p>
      );
    },
  },
};

const fullWidtOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <h2 className="xl:text-4xl mb-6 text-center">{children}</h2>;
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return <p className="xl:text-2xl text-center">{children}</p>;
    },
  },
};

const Landing = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageLanding {
          title
          description
          powerfulSentenceAboutThePlatform
          footerText
          footerButton {
            title
            url
          }
          featureDescriptions {
            raw
          }
          fullWidthText {
            raw
          }
          featuresWithIcon {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                placeholder: BLURRED
              )
            }
          }
          recognition {
            title
            text
            badges {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
              )
            }
          }
        }
      }
    `
  );

  const title = data?.title || "";
  const description = data?.description || "";
  const footerText = data?.footerText || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";
  const powerfulSentenceAboutThePlatform =
    data?.powerfulSentenceAboutThePlatform || "";
  const featureDescriptions = data?.featureDescriptions || "";
  const fullWidthText = data?.fullWidthText || "";
  const featuresWithIcon = data?.featuresWithIcon || [];
  const footerButton = data?.footerButton || {};
  const recognition = data?.recognition || {};

  return (
    <>
      <Layout location={location} hero={<HeroLanding />} hideHeader hideFooter>
        <Seo
          title={title}
          description={description}
          image={thumbnail}
          url={location.href}
        />

        <Container className="mt-6">
          <Row className="items-center">
            <Col size="w-full lg:w-1/2">
              <h1 style={{ maxWidth: 400 }}>
                {powerfulSentenceAboutThePlatform}
              </h1>
            </Col>

            <Col size="w-full lg:w-1/2" className="order-last">
              {featureDescriptions &&
                renderRichText(featureDescriptions, options)}
            </Col>
          </Row>
        </Container>

        <Container className="mt-32 xl:px-32">
          {fullWidthText && renderRichText(fullWidthText, fullWidtOptions)}
        </Container>

        <Container className="mt-32">
          <div className="grid lg:grid-cols-3 gap-12">
            {featuresWithIcon.map((item, i) => {
              const img = item?.image?.gatsbyImageData;
              const text = renderRichText(item.content, fullWidtOptions);
              return (
                <div key={i} className="flex flex-col items-center gap-8">
                  <GatsbyImage
                    image={img}
                    alt={item?.title}
                    className="w-1/3"
                  />
                  <h3 className="text-center font-normal">{text}</h3>
                </div>
              );
            })}
          </div>
        </Container>
        <Recognition
          recognition={recognition}
        />
        <FooterReadyFor
          title={footerText}
          buttons={[footerButton]} 
        />
      </Layout>
    </>
  );
};

export default Landing;
